
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import HospitalModule from "@/store/hospital";
@Component({
  name: "RegisterSuccess",
  components: {
    Header,
    Footer,
  },
})
export default class RegisterSuccess extends Vue {
  private get hospitalId() {
    return HospitalModule.hospitalId;
  }
}
